import React, { useEffect, useState } from "react";
import { useOidcUser } from "@axa-fr/react-oidc";
import queryString from "query-string";
import {
  Button,
  Card,
  H1,
  H4,
  InputField,
  LoadingView,
  Notification,
  useShowNotification,
} from "@collabodoc/tailwind-components";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { API_URLS } from "../urls";
import { ADMIN_ROLE_NAME, CLINICAL_ROLE_NAME } from "../enums";
import { useLocation } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import LinkedAccountsView from "./LinkedAccounts";
import { getTenantName } from "../utils/tenantUtils";

const MyAccountView = () => {
  const tenant = getTenantName();
  const { oidcUser, isLoading: isLoadingOicd } = useOidcUser(tenant);
  const { search } = useLocation();
  const returnUrl = queryString.parse(search).returnUrl;
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    clinicalRole: "",
    administratorRole: "",
    phoneNumber: "",
  });
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const { doFetch: doFetchUser, isLoading: isLoadingUser } = useFetchy();
  const { doFetch: saveUser } = useFetchy();
  const { notification, showNotification } = useShowNotification();

  useEffect(() => {
    if (oidcUser) {
      doFetchUser(
        `${API_URLS.GET_USERS}` + oidcUser.sub,
        getRequestInit()
      ).then((result) => {
        setUser(result.data);
      });
    }
  }, [oidcUser]);
  if (isLoadingUser || isLoadingOicd) {
    return <LoadingView />;
  }
  const handleChangeUserEmail = (e) => {
    setUser((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };
  const handleChangeUserFirstName = (e) => {
    setUser((prevState) => ({
      ...prevState,
      firstName: e.target.value,
    }));
  };
  const handleChangeUserLastName = (e) => {
    setUser((prevState) => ({
      ...prevState,
      lastName: e.target.value,
    }));
  };
  const handleChangeUserPhoneNumber = (e) => {
    setUser((prevState) => ({
      ...prevState,
      phoneNumber: e.target.value,
    }));
  };

  const handleSaveUser = async (e) => {
    e.preventDefault();
    const requestInit = getRequestInit({
      method: "PUT",
      data: user,
    });
    saveUser(API_URLS.UPDATE_USER(oidcUser.sub), requestInit)
      .then(({ response, data, error }) => {
        showNotification(
          "success",
          "Sparat!",
          `Användarinformationen har sparats.`
        );
      })
      .catch((error) => {
        showNotification(
          "error",
          "Något gick fel!",
          `Kunde inte spara uppgifterna.`
        );
      });
  };

  return (
    <>
      <Notification notification={notification} />
      <div className={"p-8 flex gap-y-4 gap-x-20 items-start flex-wrap"}>
        <div className={"basis-full"}>
          <H1>Mitt konto</H1>
        </div>
        <Card>
          <form noValidate onSubmit={handleSaveUser}>
            <Card.Body className={"flex flex-col gap-4"}>
              <H4>Kontaktuppgifter</H4>
              <InputField
                required
                type={"text"}
                width={"large"}
                label={"Förnamn"}
                onChange={handleChangeUserFirstName}
                maxLength={50}
                value={user.firstName}
              />
              <InputField
                required
                type={"text"}
                width={"large"}
                label={"Efternamn"}
                onChange={handleChangeUserLastName}
                maxLength={50}
                value={user.lastName}
              />
              <InputField
                required
                type={"email"}
                name={"email"}
                width={"large"}
                label={"E-postadress"}
                onChange={handleChangeUserEmail}
                value={user.email}
                customValidityMessages={{
                  typeMismatch: "Inte en giltig epostadress",
                }}
              />
              <InputField
                required
                type={"tel"}
                width={"large"}
                label={"Mobilnummer"}
                onChange={handleChangeUserPhoneNumber}
                value={user.phoneNumber}
                maxLength={20}
                pattern={
                  "^(([0]{1}|[0]{2}[4][6]|[+]{1}[4][6])[7]{1}\\d{1}-?\\d{7})$"
                }
                helperText={"Fyll i enligt formatet 07X-XXXXXXXXX"}
                customValidityMessages={{
                  patternMismatch: "Numret måste vara i formatet 07X-XXXXXXXXX",
                }}
              />
              <H4>Roller</H4>
              <InputField
                disabled
                type={"text"}
                width={"large"}
                label={"Klinisk roll"}
                defaultValue={
                  user.clinicalRole
                    ? CLINICAL_ROLE_NAME[user.clinicalRole]
                    : "Ingen klinisk roll"
                }
              />
              {user.administratorRole && (
                <InputField
                  disabled
                  type={"text"}
                  width={"large"}
                  label={"Administrativ roll"}
                  defaultValue={ADMIN_ROLE_NAME[user.administratorRole]}
                />
              )}
            </Card.Body>

            <Card.Footer
              className={"flex " + (returnUrl ? "justify-between" : "self-end")}
            >
              {returnUrl && (
                <Button
                  variant="secondary"
                  onClick={() => (window.location.href = returnUrl)}
                >
                  Tillbaka
                </Button>
              )}
              <Button variant={"primary"} type="submit">
                Spara
              </Button>
            </Card.Footer>
          </form>
        </Card>
        <LinkedAccountsView
          userId={oidcUser.sub}
          showNotification={showNotification}
        />
        {isSuccessfullySubmitted && <p>Sparat!</p>}
      </div>
    </>
  );
};

export default MyAccountView;
