import { getRequestInit, H1, useFetchy } from "@collabodoc/component-library";
import {
  Button,
  InputField,
  Modal,
  Textarea,
  Toggle,
} from "@collabodoc/tailwind-components";
import { API_URLS } from "../urls";

const ProductModal = ({
  product,
  open,
  setOpen,
  careCenterId,
  reFetchCareCenters,
  showNotification,
}) => {
  const { doFetch: saveProduct } = useFetchy();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const valid = event.target.checkValidity();
    if (valid) {
      const apiMethod = product?.productId ? "PUT" : "POST";
      const apiUrl = product?.productId
        ? API_URLS.UPDATE_PRODUCT(careCenterId, product.productId)
        : API_URLS.ADD_PRODUCT(careCenterId);
      const formData = new FormData(event.target);
      const data = Object.fromEntries(formData.entries());
      data["active"] = data["active"] === "on";
      if (!product?.productId) {
        data["productId"] = parseInt(product.id);
      }
      const requestInit = getRequestInit({
        method: apiMethod,
        data: data,
      });
      await saveProduct(apiUrl, requestInit).then(({ response }) => {
        if (!response?.ok) {
          showNotification(
            "error",
            "Något gick fel!",
            "Kunde inte spara produkten."
          );
        }
      });
      reFetchCareCenters();
    }
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} width="medium">
      <H1>Produktinställningar</H1>
      <form
        id="products"
        className={"flex flex-col gap-4 mt-4"}
        noValidate={true}
        onSubmit={handleSubmit}
      >
        <InputField
          label={"Namn"}
          defaultValue={product?.productName ?? product.defaultName}
          required={true}
          maxLength={100}
          width="full"
          name="productName"
          customValidityMessages={{
            valueMissing: "Produkten måste ha ett namn",
          }}
        />
        <Textarea
          label="Beskrivning"
          defaultValue={product?.description}
          maxLength={300}
          width="full"
          name="description"
          rows={2}
          allowResize={true}
        />
        <Toggle
          label={"Aktiv"}
          defaultEnabled={!!product?.productId}
          name={"active"}
        />
        <Toggle
          label={"Visa på indexsidan"}
          defaultEnabled={!!product?.showProductForPatients}
          name={"showProductForPatients"}
        />
        <div className="flex justify-between mt-4">
          <Button
            variant="secondary"
            type="button"
            onClick={() => setOpen(false)}
          >
            {"Avbryt"}
          </Button>
          <Button variant="primary" type="submit">
            {"Spara"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default ProductModal;
