import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { CareCenterContext } from "../CareCenterContext";
import { getRequestInit, H1, useFetchy } from "@collabodoc/component-library";
import {
  Button,
  Card,
  Dropdown,
  InputField,
  Notification,
  useShowNotification,
} from "@collabodoc/tailwind-components";
import { typeOptions } from "./CareCenterInformationCard";
import { API_URLS, LOCAL_URLS } from "../urls";

const AddCareCenterView = () => {
  const { careCenters, reFetchCareCenters } = useContext(CareCenterContext);
  const navigate = useNavigate();
  const [showSubmit, setShowSubmit] = useState(false);
  const [type, setType] = useState(1);
  const [parentCareCenterId, setParentCareCenterId] = useState({});
  const { doFetch: saveCareCenter } = useFetchy();
  const { notification, showNotification } = useShowNotification();

  const handleSubmitCareCenter = async (event) => {
    event.preventDefault();
    const valid = event.target.checkValidity();
    if (valid) {
      const formData = new FormData(event.target);
      const data = Object.fromEntries(formData.entries());
      data["careCenterType"] = parseInt(data["type[id]"]);
      data["parentId"] = data["parentId[id]"];
      const requestInit = getRequestInit({
        method: "POST",
        data: data,
      });
      try {
        const { response, data: creationData } = await saveCareCenter(
          API_URLS.CREATE_CARECENTER,
          requestInit
        );

        if (!response.ok) {
          showNotification(
            "error",
            "Något gick fel!",
            "Kunde inte spara enheten."
          );
          return; // Stop further execution if there's an error
        }
        await reFetchCareCenters(); // Wait for the data to be re-fetched and updated
        navigate(LOCAL_URLS.CARECENTER(creationData.identifier));
      } catch (error) {
        console.error("Error saving or fetching care centers:", error);
      }
    }
  };

  const careCenterOptions = careCenters.map((careCenter) => ({
    id: careCenter.identifier,
    text: careCenter.name,
  }));

  return (
    <>
      <Notification notification={notification} />
      <Card>
        <form
          id="details"
          noValidate={true}
          onSubmit={handleSubmitCareCenter}
          onChange={(e) => setShowSubmit(e.target.form.checkValidity())}
        >
          <Card.Header>
            <div className={"basis-full"}>
              <H1>Lägg till enhet</H1>
            </div>
          </Card.Header>
          <Card.Body>
            <div className={"flex gap-y-4 gap-x-4 flex-wrap"}>
              <InputField
                required={true}
                label={"Namn"}
                type={"text"}
                name={"name"}
                width={"large"}
                customValidityMessages={{
                  valueMissing: "Enheten måste ha ett namn",
                }}
              />
              <InputField
                required={true}
                label={"Visningsnamn i statistiken"}
                type={"text"}
                name={"description"}
                width={"large"}
                customValidityMessages={{
                  valueMissing: "Enheten måste ha ett visningsnamn",
                }}
              />
              <Dropdown
                options={typeOptions}
                selected={type}
                setSelected={(id) => setType(id)}
                name={"type"}
                label={"Enhetstyp"}
              />
              <Dropdown
                options={careCenterOptions}
                selected={parentCareCenterId}
                setSelected={(id) => setParentCareCenterId(id)}
                name={"parentId"}
                label={"Huvudenhet"}
              />
            </div>
          </Card.Body>
          <Card.Footer className="flex justify-between">
            <Button
              variant="secondary"
              type={"button"}
              onClick={() => navigate(LOCAL_URLS.CARECENTERS)}
            >
              Avbryt
            </Button>
            <Button variant="primary" type="submit" disabled={!showSubmit}>
              {"Skapa enhet"}
            </Button>
          </Card.Footer>
        </form>
      </Card>
    </>
  );
};

export default AddCareCenterView;
