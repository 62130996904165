import {
  Button,
  Card,
  Dropdown,
  InputField,
} from "@collabodoc/tailwind-components";
import { getRequestInit, H2, useFetchy } from "@collabodoc/component-library";
import { useState } from "react";
import { API_URLS, LOCAL_URLS } from "../urls";
import { useNavigate } from "react-router-dom";

export const typeOptions = [
  { id: 1, text: "Vårdcentral" },
  { id: 2, text: "SäBo" },
  { id: 3, text: "Organisatorisk enhet" },
];

const CareCenterInformationCard = ({
  careCenter,
  careCenters,
  showNotification,
}) => {
  const [type, setType] = useState(careCenter.careCenterType);
  const [parentCareCenterId, setParentCareCenterId] = useState(
    careCenter.parentId
  );
  const { doFetch: saveCareCenter } = useFetchy();
  const navigate = useNavigate();
  const [showSubmit, setShowSubmit] = useState(false);

  const handleSubmitCareCenter = (event) => {
    event.preventDefault();
    const valid = event.target.checkValidity();
    if (valid) {
      const formData = new FormData(event.target);
      const data = Object.fromEntries(formData.entries());
      data["careCenterType"] = parseInt(data["type[id]"]);
      data["parentId"] = data["parentId[id]"];
      const requestInit = getRequestInit({
        method: "PUT",
        data: data,
      });
      saveCareCenter(
        API_URLS.UPDATE_CARECENTER(careCenter.identifier),
        requestInit
      ).then(({ response, data, error }) => {
        if (!response.ok) {
          showNotification(
            "error",
            "Något gick fel!",
            "Kunde inte uppdatera enheten."
          );
        }
      });
    }
  };

  const careCenterOptions = [
    { id: null, text: "Ingen huvudenhet" },
    ...careCenters
      .filter((cc) => cc.identifier !== careCenter.identifier)
      .map((careCenter) => ({
        id: careCenter.identifier,
        text: careCenter.name,
      })),
  ];

  const handleChangeType = (type) => {
    setType(type);
    setShowSubmit(true);
  };

  const handleChangeParentOrg = (id) => {
    setParentCareCenterId(id);
    setShowSubmit(true);
  };

  console.log(careCenter);

  return (
    <Card className="basis-0 grow">
      <Card.Header>
        <H2>Information</H2>
      </Card.Header>
      <Card.Body className="flex flex-col">
        <form
          className={"flex flex-col gap-2 mt-2"}
          id="details"
          noValidate={true}
          onSubmit={handleSubmitCareCenter}
          onChange={(e) => setShowSubmit(e.target.form.checkValidity())}
        >
          <div>
            <InputField
              required={true}
              label={"Namn"}
              type={"text"}
              name={"name"}
              width={"large"}
              defaultValue={careCenter.name}
              customValidityMessages={{
                valueMissing: "Enheten måste ha ett namn",
              }}
            />
            <InputField
              required={true}
              label={"Visningsnamn i statistiken"}
              type={"text"}
              name={"description"}
              width={"large"}
              defaultValue={careCenter.description ?? ""}
              customValidityMessages={{
                valueMissing: "Enheten måste ha ett visningsnamn",
              }}
            />
            <InputField
              label={"Telefonnummer"}
              type={"tel"}
              width={"large"}
              name={"phoneNumber"}
              defaultValue={careCenter.phoneNumber}
            />
            <InputField
              label={"Adress"}
              type={"text"}
              width={"large"}
              name={"address"}
              defaultValue={careCenter.address}
            />
            <InputField
              label={"Stad"}
              type={"text"}
              width={"large"}
              name={"city"}
              defaultValue={careCenter.city}
            />
            <InputField
              label={"Kommun"}
              type={"text"}
              width={"large"}
              name={"municipality"}
              defaultValue={careCenter.municipality}
            />
            <InputField
              label={"Kommunkod"}
              type={"text"}
              width={"large"}
              name={"county"}
              defaultValue={careCenter.county}
            />
            <InputField
              label={"HSA-Id"}
              type={"text"}
              width={"large"}
              name={"hsaId"}
              defaultValue={careCenter.hsaId}
            />
            <InputField
              label={"ZendeskId"}
              type={"number"}
              width={"large"}
              name={"zendeskId"}
              defaultValue={careCenter.zendeskId}
            />
            <Dropdown
              options={typeOptions}
              selected={type}
              setSelected={(id) => handleChangeType(id)}
              name={"type"}
              label={"Enhetstyp"}
            />
          </div>
          {!careCenter.careCenterChildren.length && (
            <Dropdown
              options={careCenterOptions}
              selected={parentCareCenterId}
              setSelected={(id) => handleChangeParentOrg(id)}
              name={"parentId"}
              label={"Huvudenhet"}
            />
          )}
          <div className="flex justify-between mt-4">
            <Button variant="primary" type="submit" disabled={!showSubmit}>
              {"Spara"}
            </Button>
          </div>
        </form>
        {!!careCenter.careCenterChildren.length && (
          <>
            <H2 className="mt-4 px-1">Underenheter</H2>
            <div className="px-1 text-sm text-gray-500">
              {careCenter.careCenterChildren.map(({ identifier, name }) => (
                <div
                  key={identifier}
                  className="flex justify-between py-2 sm:p-3"
                >
                  <div>{name}</div>
                  <div className="text-right">
                    <Button
                      variant="primary"
                      onClick={() =>
                        navigate(LOCAL_URLS.CARECENTER(identifier))
                      }
                    >
                      Gå till enhet
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default CareCenterInformationCard;
