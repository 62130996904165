const PRODUCT = {
  DROP_IN: { id: 1, defaultName: "Direktmottagningen" },
  CONTACT: { id: 2, defaultName: "Digital Kontakt" },
  SICK_LEAVE: { id: 3, defaultName: "Sjukskrivning" },
  VACCINATION: { id: 4, defaultName: "Vaccination" },
  DIGITAL_FIRST: { id: 5, defaultName: "Digital mottagning" },
  MEDICAL_ROUND: { id: 7, defaultName: "Collabodoc Rondstöd" },
  VIDEO: { id: 8, defaultName: "Collabodoc Video" },
};

const DROPIN_SETTING = {
  REMOTE_REGISTRATION: 7,
  WAITING_ROOM: 9,
};

export { PRODUCT, DROPIN_SETTING };
