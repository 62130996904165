import { Table } from "@collabodoc/tailwind-components";
import { useNavigate } from "react-router-dom";
import { LOCAL_URLS } from "../urls";

const UserRow = ({ user, isLoading }) => {
  const navigate = useNavigate();

  const isLoadingClass = isLoading ? "animate-pulse" : "";

  return (
    <Table.Row
      onClick={() => navigate(LOCAL_URLS.EDIT_USER(user.id))}
      className={"cursor-pointer " + isLoadingClass}
    >
      <Table.Data className={"lg:hidden w-min truncate"}>
        <div>{`${user.firstName} ${user.lastName}`}</div>
        <div>{user.email}</div>
      </Table.Data>
      <Table.Data className={"hidden lg:table-cell w-min truncate"}>
        {user.firstName}
      </Table.Data>
      <Table.Data className={"hidden lg:table-cell w-min truncate"}>
        {user.lastName}
      </Table.Data>
      <Table.Data className={"hidden lg:table-cell truncate"}>
        {user.email}
      </Table.Data>
      <Table.Data className={"lg:hidden truncate"}>
        <div>{user.clinicalRoleString}</div>
        <div>{user.administratorRoleString}</div>
      </Table.Data>
      <Table.Data className={"hidden lg:table-cell truncate"}>
        {user.clinicalRoleString}
      </Table.Data>
      <Table.Data className={"hidden lg:table-cell w-min truncate"}>
        {user.administratorRoleString}
      </Table.Data>
      <Table.Data className={"hidden sm:table-cell w-min truncate"}>
        {user.lastLogin}
      </Table.Data>
    </Table.Row>
  );
};

export default UserRow;
