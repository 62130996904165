import { H4 } from "@collabodoc/component-library";
import React, { useContext } from "react";
import ReactHtmlParser, { domToReact } from "html-react-parser";
import { ThemeContext } from "styled-components";

const ReleaseNotes = ({ releaseNotes }) => {
  const theme = useContext(ThemeContext);

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return;

      if (attribs.class === "link") {
        return (
          <a
            href={attribs.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: theme.typography.linkColor }}
          >
            {domToReact(children, options)}
          </a>
        );
      }
    },
  };

  return (
    <div className={"lg:grid-cols-4 lg:grid lg:gap-10"}>
      {releaseNotes.section.map((section, key) => {
        return (
          <div className={"sm:w-full"} key={key}>
            <H4>{section.heading}</H4>
            {ReactHtmlParser(section.paragraph, options)}
            <br />
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default ReleaseNotes;
