const CLINICAL_ROLE_NAME = {
  1: "Läkare",
  2: "Sjuksköterska",
  4: "Sekreterare",
  5: "Fysioterapeut",
  6: "Psykolog",
  7: "Kurator",
  8: "Arbetsterapeut",
};

const CLINICAL_ROLE = {
  DoctorUser: 1,
  NurseUser: 2,
  RegistratorUser: 3,
  SecretaryUser: 4,
  PhysiotherapistUser: 5,
  PsychologistUser: 6,
  CounselorUser: 7,
  OccupationalTherapistUser: 8,
};

const ADMIN_ROLE_NAME = {
  1: "Collabodoc IT-Support",
  2: "Verksamhetschef",
  3: "Kundadministratör",
  4: "Vårdcentralsadministratör (Super-user)",
};

const LINKED_ACCOUNT_DOMAINS = {
  BankID: "BankID",
  Siths: "Siths",
};

const ADMIN_ROLE = {
  Admin: 1,
  ManagerUser: 2,
  ClientAdmin: 3,
  CarecenterAdmin: 4,
};

export {
  CLINICAL_ROLE_NAME,
  CLINICAL_ROLE,
  ADMIN_ROLE_NAME,
  ADMIN_ROLE,
  LINKED_ACCOUNT_DOMAINS,
};
