import { LoadingView } from "@collabodoc/tailwind-components";
import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { hasAdminRole, useFetchy } from "@collabodoc/component-library";
import { useOidcUser } from "@axa-fr/react-oidc";
import { API_URLS } from "./urls";
import psl from "psl";
import { getTenantName } from "./utils/tenantUtils";

const COOKIE = { CARE_CENTER_COOKIE: "CareCenterCookie" };

const parsed = psl.parse(window.location.hostname);
const domain = parsed.domain ? parsed.domain : parsed.input;

const CareCenterContext = createContext({});

const getCookieName = (siteName) => {
  return `${siteName}-${COOKIE.CARE_CENTER_COOKIE}`;
};

const getCookie = (siteName) => {
  const cookieName = getCookieName(siteName);
  const cookie = Cookies.get(cookieName);
  const decodedCookie = cookie && window.atob(cookie);
  if (!decodedCookie) return null;
  return JSON.parse(decodedCookie);
};

const setCookie = (userId, careCenter, siteName) => {
  const cookieObject = {
    userId,
    careCenterId: careCenter.id,
    identifier: careCenter.identifier,
  };
  const serializedCookieObject = JSON.stringify(cookieObject);
  const encodedCookieObject = window.btoa(serializedCookieObject);
  const cookieName = getCookieName(siteName);
  Cookies.set(cookieName, encodedCookieObject, {
    domain: domain,
    expires: 30,
  });
};

const getCareCenterIdsFromUser = (user) => {
  if (!user || !user.carecenteridentifiers) {
    return [];
  }
  //  If user belongs to multiple carecenters this is a array
  if (Array.isArray(user.carecenteridentifiers)) {
    return user.carecenteridentifiers;
  }
  //  Only one carecenter so type is string
  return [user.carecenteridentifiers];
};

const getCareCenterIdFromCookie = (user, siteName) => {
  const cookie = getCookie(siteName);
  if (!user) return null;
  if (!cookie) return getCareCenterIdsFromUser(user)[0];
  if (cookie.userId === user?.sub) {
    const careCenterId = cookie.identifier;
    if (getCareCenterIdsFromUser(user).find((id) => id === careCenterId)) {
      return careCenterId;
    }
  }
  Cookies.remove(getCookieName(siteName), { domain: domain });
  return getCareCenterIdsFromUser(user)[0];
};

const CareCenterContextProvider = ({ children }) => {
  const tenant = getTenantName();
  const { oidcUser, oidcUserLoadingState } = useOidcUser(tenant);
  const siteName = window.REACT_APP_CAREGIVER;
  const apiUrl = API_URLS.GET_CARECENTERS;

  const [currentCareCenterId, setCurrentCareCenterId] = useState(null);

  useEffect(() => {
    if (oidcUser) {
      setCurrentCareCenterId(getCareCenterIdFromCookie(oidcUser, siteName));
    }
  }, [oidcUser, siteName]);

  const {
    data: careCenters = [],
    isLoading: isLoadingCareCenters,
    doFetch: reFetchCareCenters,
  } = useFetchy(apiUrl);

  useEffect(() => {
    if (oidcUser && currentCareCenterId) {
      const careCenter = careCenters.find(
        (careCenter) => careCenter.identifier === currentCareCenterId
      );
      if (careCenter) {
        setCookie(oidcUser.sub, careCenter, siteName);
      }
    }
  }, [oidcUser, currentCareCenterId, siteName, careCenters]);

  const userCareCenterIds = useMemo(() => {
    return [...(oidcUser?.carecenteridentifiers ?? [])];
  }, [oidcUser?.carecenteridentifiers]);

  const userCareCenters = useMemo(() => {
    if (hasAdminRole(oidcUser?.roles)) {
      return careCenters;
    }
    return [
      ...careCenters.filter((careCenter) =>
        userCareCenterIds.includes(careCenter.identifier)
      ),
    ];
  }, [userCareCenterIds, careCenters]);

  const currentCareCenter = useMemo(
    () =>
      userCareCenters.find(
        (careCenter) => careCenter.identifier === currentCareCenterId
      ),
    [userCareCenters, currentCareCenterId]
  );

  const getCareCenterByIdentifier = useCallback(
    (identifier) =>
      userCareCenters.find(
        (userCareCenter) => userCareCenter.identifier === identifier
      ),
    [userCareCenters]
  );

  const setCareCenter = useCallback(
    (careCenterId) => {
      const userHasCareCenter = userCareCenterIds.includes(careCenterId);
      if (!userHasCareCenter) {
        return;
      }
      setCurrentCareCenterId(careCenterId);
    },
    [userCareCenterIds]
  );

  const removeCareCenter = useCallback(() => {
    Cookies.remove(getCookieName(siteName), { domain: domain });
    setCurrentCareCenterId(null);
  }, [siteName]);

  if (!oidcUser) {
    return <LoadingView />;
  }

  if (!careCenters.length) {
    return <LoadingView />;
  }

  return (
    <CareCenterContext.Provider
      value={{
        careCenters: userCareCenters,
        currentCareCenter: currentCareCenter,
        currentCareCenterId: currentCareCenterId,
        isLoadingCareCenters: isLoadingCareCenters,
        getCareCenterByIdentifier: getCareCenterByIdentifier,
        setCurrentCareCenter: setCareCenter,
        removeCurrentCareCenter: removeCareCenter,
        reFetchCareCenters: reFetchCareCenters,
      }}
    >
      {children}
    </CareCenterContext.Provider>
  );
};

export { CareCenterContext, CareCenterContextProvider };
