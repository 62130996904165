import { useContext, useEffect, useRef, useState } from "react";
import {
  getRequestInit,
  H1,
  H2,
  H4,
  useFetchy,
} from "@collabodoc/component-library";
import {
  Button,
  Card,
  LoadingView,
  Modal,
  useShowNotification,
  Notification,
} from "@collabodoc/tailwind-components";
import { useHistory, useNavigate, useParams } from "react-router-dom";
import { API_URLS, LOCAL_URLS } from "../urls";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { CareCenterContext } from "../CareCenterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UserInfoCard from "./UserInfoCard";
import UserAuthMethodsCard from "./UserAuthMethodsCard";
import { getTenantName } from "../utils/tenantUtils";

const UserView = () => {
  const { userId } = useParams();
  const tenant = getTenantName();
  const { accessToken } = useOidcAccessToken(tenant);
  const { oidcUser } = useOidcUser(tenant);
  const [loadingUser, setLoadingUser] = useState(false);
  const { doFetch: createOrUpdateUser } = useFetchy();
  const { doFetch: saveCareCenters } = useFetchy();
  const { doFetch: deleteUser } = useFetchy();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [saveOrgUnitsEnabled, setSaveOrgUnitsEnabled] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    clinicRole: null,
    administratorRole: null,
    bankid: "",
    hsaId: "",
  });
  const [organizationalUnits, setOrganizationalUnits] = useState([]);
  const { careCenters } = useContext(CareCenterContext);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const { notification, showNotification } = useShowNotification();

  const apiUrl = userId ? API_URLS.UPDATE_USER(userId) : API_URLS.CREATE_USER;
  const apiMethod = userId ? "PUT" : "POST";
  const handleSendUser = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      const requestInit = getRequestInit({
        accessToken,
        method: apiMethod,
        data: formData,
      });
      createOrUpdateUser(apiUrl, requestInit).then(
        ({ response, data, error }) => {
          if (!response.ok) {
            if (response.status === 409) {
              setModalMessage(`Användaren finns redan. Läser in användaren.`);
              navigate({
                pathname: LOCAL_URLS.EDIT_USER(error),
              });
              setShowModal(true);
            } else {
              showNotification(
                "error",
                "Något gick fel!",
                `Kunde inte ${userId ? "uppdatera" : "skapa"} användare!`
              );
            }
          } else {
            showNotification(
              "success",
              `Användaren ${userId ? "uppdaterades" : "skapades"}!`
            );
            navigate({
              pathname: LOCAL_URLS.EDIT_USER(data),
            });
            setSubmitEnabled(false);
          }
        }
      );
    }
  };
  const handleSaveCareCenters = async (e) => {
    e.preventDefault();
    const init = getRequestInit({
      accessToken,
      method: "PUT",
      data: { organizationalUnits: organizationalUnits },
    });
    const url = API_URLS.SAVE_CARECENTERS_ON_USER(userId);
    saveCareCenters(url, init).then((result) => {
      if (!result.response.ok) {
        console.error("Failed saving organizational units.");
      } else {
        setSaveOrgUnitsEnabled(false);
      }
    });
  };

  const handleDeleteUser = () => {
    const init = getRequestInit({
      accessToken,
      method: "DELETE",
    });
    setShowDeleteUserModal(false);
    deleteUser(API_URLS.DELETE_USER(userId), init).then((result) => {
      if (!result.response.ok) {
        setModalMessage("Kunde inte ta bort användaren!");
        setShowModal(true);
      } else {
        navigate(LOCAL_URLS.USER_LIST);
      }
    });
  };

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(API_URLS.GET_USER(userId));
      if (response.ok) {
        const data = await response.json();
        const {
          organizationalUnits: orgUnits,
          personalNumber,
          ...userData
        } = data;
        setFormData({ bankid: personalNumber, ...userData });
        setOrganizationalUnits(orgUnits);
      }
    };

    if (userId) {
      setLoadingUser(true);
      fetchUser().finally(() => setLoadingUser(false));
    }
  }, [userId]);

  const handleRemoveCareCenter = (e, identifier) => {
    e.preventDefault();
    setOrganizationalUnits((prevState) => [
      ...prevState.filter((x) => x !== identifier),
    ]);
    setSaveOrgUnitsEnabled(true);
  };

  const handleAddCareCenter = (identifier) => {
    setOrganizationalUnits((prevState) => [...prevState, identifier]);
    setSaveOrgUnitsEnabled(true);
  };

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setSubmitEnabled(formRef.current.checkValidity());
  };

  if (loadingUser) {
    return <LoadingView />;
  }

  return (
    <>
      <Notification notification={notification} />
      <div className={"flex flex-col mt-4 px-8 gap-4"}>
        <div className={"basis-full"}>
          <H1>{userId ? "Redigera användare" : "Ny användare"}</H1>
        </div>
        <div className={"flex gap-y-4 gap-x-4 items-stretch flex-wrap"}>
          <UserInfoCard
            formData={formData}
            formRef={formRef}
            handleFormChange={handleFormChange}
            handleSendUser={handleSendUser}
            setFormData={setFormData}
            submitEnabled={submitEnabled}
            setSubmitEnabled={setSubmitEnabled}
            userId={userId}
            oidcUser={oidcUser}
          />
          {userId && (
            <div className={"flex flex-col justify-between gap-4"}>
              <Card className={"h-full"}>
                <Card.Header>
                  <H2>Enheter</H2>
                </Card.Header>
                <Card.Body className={"h-full"}>
                  <div className="flex justify-between w-full gap-8">
                    <div className={"w-60"}>
                      <H4>Tillagda enheter</H4>
                      <div className={"flex-col overflow-y-auto"}>
                        {careCenters
                          .filter((careCenter) =>
                            organizationalUnits.includes(careCenter.identifier)
                          )
                          .map((cc) => {
                            return (
                              <div
                                className={"flex justify-between items-center"}
                                key={cc.identifier}
                              >
                                {cc.name}
                                <Button
                                  type={"button"}
                                  onClick={(e) =>
                                    handleRemoveCareCenter(e, cc.identifier)
                                  }
                                  variant={"secondary"}
                                  pillShape={true}
                                  className={"align-middle !py-1 !px-2 !m-0.5"}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className={"text-left w-60"}>
                      <H4>Tillgängliga enheter</H4>
                      <div className={"flex-col overflow-y-scroll"}>
                        {careCenters
                          .filter(
                            (careCenter) =>
                              !organizationalUnits.includes(
                                careCenter.identifier
                              )
                          )
                          .map((cc) => {
                            return (
                              <div
                                className={"flex justify-between items-center"}
                                key={cc.identifier}
                              >
                                {cc.name}
                                <Button
                                  onClick={() =>
                                    handleAddCareCenter(cc.identifier)
                                  }
                                  className={"align-middle !py-1 !px-2 my-1"}
                                  variant={"secondary"}
                                  pillShape={true}
                                  type={"button"}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className={"w-full flex flex-row justify-end"}>
                    <Button
                      disabled={!saveOrgUnitsEnabled}
                      variant={"primary"}
                      type={"button"}
                      width={"small"}
                      onClick={handleSaveCareCenters}
                    >
                      Spara
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
              <UserAuthMethodsCard
                setFormData={setFormData}
                userId={userId}
                formData={formData}
                showNotification={showNotification}
              />
            </div>
          )}
        </div>
        <div className={"basis-full"}>
          <Card className={"w-full"}>
            <Card.Body className={"justify-between"}>
              <Button
                variant="secondary"
                type={"button"}
                onClick={() => navigate(LOCAL_URLS.USER_LIST)}
              >
                Tillbaka
              </Button>
              {userId && (
                <Button
                  variant={"danger"}
                  type={"button"}
                  onClick={() => setShowDeleteUserModal(true)}
                >
                  Ta bort användare
                </Button>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
      <Modal
        position="top"
        setOpen={setShowDeleteUserModal}
        open={showDeleteUserModal}
        width="small"
      >
        <div className="flex flex-col items-center gap-4">
          <div className={"text-center"}>
            <p>
              Är du säker på att du vill ta bort användaren {formData.firstName}{" "}
              {formData.lastName}
            </p>
          </div>
          <Button
            variant={"danger"}
            type={"button"}
            width={"full"}
            onClick={handleDeleteUser}
          >
            Ja
          </Button>
          <Button
            variant={"secondary"}
            type={"button"}
            width={"full"}
            onClick={() => setShowDeleteUserModal(false)}
          >
            Nej
          </Button>
        </div>
      </Modal>
      <Modal
        position="top"
        setOpen={setShowModal}
        open={showModal}
        width="small"
      >
        <div className="flex flex-col items-center gap-4">
          <div className={"text-center"}>
            <p>{modalMessage}</p>
          </div>
          <Button
            variant={"primary"}
            type={"button"}
            width={"full"}
            onClick={() => setShowModal(false)}
          >
            Okej
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UserView;
