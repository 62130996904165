import {
  ADMINISTRATORROLE,
  getRequestInit,
  H1,
  useFetchy,
} from "@collabodoc/component-library";
import React, { useContext, useEffect, useState } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { API_URLS, LOCAL_URLS } from "../urls";
import UserRow from "./UserRow";
import { multiFilter } from "../utils/multiFilter";
import {
  Button,
  Dropdown,
  InputField,
  LoadingView,
  Table,
} from "@collabodoc/tailwind-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { CareCenterContext } from "../CareCenterContext";
import { ADMIN_ROLE_NAME, CLINICAL_ROLE_NAME } from "../enums";
import { getTenantName } from "../utils/tenantUtils";

const UserListView = () => {
  const tenant = getTenantName();
  const { accessToken } = useOidcAccessToken(tenant);
  const [searchString, setSearchString] = useState("");
  const navigate = useNavigate();
  const {
    careCenters: organizationalUnitsList = [],
    currentCareCenterId,
    isLoadingCareCenters,
  } = useContext(CareCenterContext);
  const [currentOrganizationalUnit, setCurrentOrganizationalUnit] = useState();

  const { data: userList = [], isLoading: isLoadingUserList } = useFetchy(
    `${API_URLS.GET_USERS}`,
    getRequestInit({ accessToken })
  );

  useEffect(() => {
    setCurrentOrganizationalUnit(currentCareCenterId);
  }, [currentCareCenterId]);

  if (isLoadingUserList || isLoadingCareCenters) {
    return <LoadingView />;
  }

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const userListWithEnums = userList.map((user) => ({
    ...user,
    clinicalRoleString: CLINICAL_ROLE_NAME[user.clinicalRole],
    administratorRoleString: ADMIN_ROLE_NAME[user.administratorRole],
  }));

  let filteredUsers = multiFilter(userListWithEnums, searchString, [
    "firstName",
    "lastName",
    "email",
    "clinicalRoleString",
    "administratorRoleString",
  ]);

  const handleChangeOrganizationalUnit = (e) => {
    setCurrentOrganizationalUnit(e);
  };

  const organizationalUnitsOptions = [
    { id: null, text: "Alla enheter" },
    ...(organizationalUnitsList?.map((u) => ({
      id: u.identifier,
      text: u.name,
    })) || []),
  ];

  if (currentOrganizationalUnit) {
    filteredUsers = filteredUsers.filter((x) =>
      x.organizationalUnits.includes(currentOrganizationalUnit)
    );
  }

  return (
    <div className={"pt-20 px-4 w-full"}>
      <div className={"flex flex-col"}>
        <H1>Användarhantering</H1>
      </div>
      <div className={"flex justify-between pb-12 pt-4 flex-wrap"}>
        <div className={"flex flex-row gap-4"}>
          <Dropdown
            placeholder={"Välj vårdcentral"}
            options={organizationalUnitsOptions}
            setSelected={(id) => handleChangeOrganizationalUnit(id)}
            sort={true}
            selected={currentOrganizationalUnit}
            width={"large"}
          />
          <Button onClick={() => navigate(LOCAL_URLS.ADD_USER)}>
            Skapa användare
          </Button>
        </div>
        <InputField
          placeholder={"Sök"}
          className={"mt-1"}
          onChange={(e) => handleSearch(e)}
          value={searchString}
          type={"search"}
          startIcon={
            <FontAwesomeIcon
              aria-hidden="true"
              className="h-4 w-4 text-gray-400"
              icon={{
                icon: [
                  512,
                  512,
                  [128269, "search"],
                  "f002",
                  "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z",
                ],
                iconName: "magnifying-glass",
                prefix: "far",
              }}
            />
          }
        />
      </div>
      <div className={"flex"}>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header className={"w-min lg:hidden truncate"}>
                Användare
              </Table.Header>
              <Table.Header className={"w-min hidden lg:table-cell truncate"}>
                Förnamn
              </Table.Header>
              <Table.Header className={"w-min hidden lg:table-cell truncate"}>
                Efternamn
              </Table.Header>
              <Table.Header className={"hidden lg:table-cell truncate"}>
                E-postadress
              </Table.Header>
              <Table.Header className={"w-min lg:hidden truncate"}>
                Roller
              </Table.Header>
              <Table.Header className={"w-min hidden lg:table-cell truncate"}>
                Klinisk roll
              </Table.Header>
              <Table.Header className={"w-min hidden lg:table-cell truncate"}>
                Administratörsroll
              </Table.Header>
              <Table.Header className={"w-min hidden sm:table-cell truncate"}>
                Senast inloggad
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filteredUsers?.map((user) => (
              <UserRow
                key={user.id}
                user={user}
                isLoading={isLoadingUserList}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default UserListView;
