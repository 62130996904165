// 1. parse the window.location to find the tenant from the pattern https://<tenant>.<domain>.com, if it is localhost return default

const parseTenantFromUrl = () => {
  const host = window.location.host;
  if (host.includes("localhost")) return "localhost";

  const tenant = host.split(".")[0];
  return tenant;
};

const getTenant = (tenant) => {
  return window.env.tenantOverrides[tenant] || tenant;
}; // 3. return the tenant name
const getTenantName = () => {
  const parsedTenant = parseTenantFromUrl();
  const tenant = getTenant(parsedTenant);
  return tenant;
};

export { getTenantName };
