const EXTERNAL_URLS = {
  MANAGE: `${window.REACT_APP_CARECENTER_API_URL}/identity/manage`,
  USER: `${window.REACT_APP_CARECENTER_API_URL}/identity/user`,
  ADDUSER: `${window.REACT_APP_CARECENTER_API_URL}/identity/user/add`,
  SETTINGS: `${window.REACT_APP_CARECENTER_API_URL}/identity/carecenter/settings`,
  ADDCARECENTER: `${window.REACT_APP_CARECENTER_API_URL}/identity/carecenter/add`,
};
const LOCAL_URLS = {
  USER_LIST: `/users/`,
  ADD_USER: `/users/user`,
  EDIT_USER: (userId) => `/users/user/${userId}`,
  CARECENTER: (id) => `/carecenters/${id}`,
  CARECENTERS: `/carecenters/`,
  NEW_CARECENTER: `/carecenters/new`,
  MANAGE: `/myaccount`,
};

const CARECENTER_API = `${window.REACT_APP_CARECENTER_API_URL}/api`;
const USER_API = `${window.REACT_APP_USER_API_URL}/api`;

const API_URLS = {
  GET_CARECENTERS: `${CARECENTER_API}/carecenters/`,
  CREATE_CARECENTER: `${CARECENTER_API}/carecenter-management`,
  UPDATE_CARECENTER: (id) => `${CARECENTER_API}/carecenter-management/${id}`,
  ADD_PRODUCT: (id) => `${CARECENTER_API}/carecenter-management/${id}/products`,
  UPDATE_PRODUCT: (id, productId) =>
    `${CARECENTER_API}/carecenter-management/${id}/products/${productId}`,
  GET_USERS: `${USER_API}/users/`,
  GET_USER: (userId) => `${USER_API}/usermanagement/users/${userId}`,
  DELETE_USER: (userId) => `${USER_API}/usermanagement/users/${userId}`,
  CREATE_USER: `${USER_API}/usermanagement/users`,
  UPDATE_USER: (userId) => `${USER_API}/usermanagement/users/${userId}`,
  SAVE_CARECENTERS_ON_USER: (userId) =>
    `${USER_API}/usermanagement/users/${userId}/organizationalunits`,
  LINKED_ACCOUNTS_BANKID: (userId) =>
    `${USER_API}/usermanagement/users/${userId}/linkedaccounts/bankid`,
  LINKED_ACCOUNTS_SITHS: (userId) =>
    `${USER_API}/usermanagement/users/${userId}/linkedaccounts/siths`,
  LINKED_ACCOUNTS: (userId) =>
    `${USER_API}/usermanagement/users/${userId}/linkedaccounts`,
};

export { LOCAL_URLS, API_URLS };
