import { H1 } from "@collabodoc/component-library";
import {
  Button,
  InputField,
  LoadingView,
  Table,
} from "@collabodoc/tailwind-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CareCenterContext } from "../CareCenterContext";
import { multiFilter } from "../utils/multiFilter";
import { typeOptions } from "./CareCenterInformationCard";
import { LOCAL_URLS } from "../urls";

const CareCentersView = () => {
  const [searchString, setSearchString] = useState("");
  const { careCenters = [], isLoadingCareCenters } =
    useContext(CareCenterContext);
  const navigate = useNavigate();

  let filteredUnits = multiFilter(careCenters, searchString, [
    "name",
    "municipality",
  ]);

  const handleSearch = (e) => {
    setSearchString(e);
  };

  if (isLoadingCareCenters) {
    return <LoadingView />;
  }

  const isLoadingClass = isLoadingCareCenters ? "animate-pulse" : "";

  return (
    <div className={"pt-20 px-4 w-full"}>
      <div className={"flex flex-col"}>
        <H1>Enheter</H1>
      </div>
      <div className={"flex justify-between pb-12 pt-4 flex-wrap"}>
        <Button onClick={() => navigate(LOCAL_URLS.NEW_CARECENTER)}>
          Skapa enhet
        </Button>
        <InputField
          placeholder={"Sök"}
          onChange={(e) => handleSearch(e.target.value)}
          value={searchString}
          width={"large"}
          type={"search"}
          startIcon={
            <FontAwesomeIcon
              aria-hidden="true"
              className="h-4 w-4 text-gray-400"
              icon={{
                icon: [
                  512,
                  512,
                  [128269, "search"],
                  "f002",
                  "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z",
                ],
                iconName: "magnifying-glass",
                prefix: "far",
              }}
            />
          }
        />
      </div>
      <div className={"flex"}>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header className={"w-min truncate"}>Namn</Table.Header>
              <Table.Header className={"w-min truncate"}>
                Enhetstyp
              </Table.Header>
              <Table.Header className={"w-min truncate"}>Kommun</Table.Header>
              <Table.Header className={"w-min truncate"}>
                Underenheter
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {filteredUnits?.map((unit) => (
              <Table.Row
                className={"cursor-pointer " + isLoadingClass}
                key={unit.identifier}
                onClick={() => navigate(unit.identifier)}
              >
                <Table.Data className={"w-min truncate"}>
                  {unit.name}
                </Table.Data>
                <Table.Data className={"w-min truncate"}>
                  {
                    typeOptions.find(
                      (option) => option.id === unit.careCenterType
                    ).text
                  }
                </Table.Data>
                <Table.Data className={"w-min truncate"}>
                  {unit.municipality}
                </Table.Data>
                <Table.Data className={"w-min truncate"}>
                  {unit.careCenterChildren.length}
                </Table.Data>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default CareCentersView;
