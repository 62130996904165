import React from "react";
import { useOidc } from "@axa-fr/react-oidc";
import {
  Button,
  Card,
  H4,
  H6,
  InputField,
  LoadingView,
} from "@collabodoc/tailwind-components";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { API_URLS, LOCAL_URLS } from "../urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { getTenantName } from "../utils/tenantUtils";

const LinkedAccountsView = ({ userId, showNotification }) => {
  const tenant = getTenantName();
  const { login } = useOidc(tenant);

  const {
    data: linkedAccountsFetched = [],
    isLoading: isLoadingLinkedAccounts,
    doFetch: refetchLinkedAccounts,
  } = useFetchy(`${API_URLS.LINKED_ACCOUNTS(userId)}`);
  const { doFetch: deleteLinkedAccount } = useFetchy();

  if (isLoadingLinkedAccounts) {
    return <LoadingView />;
  }
  const handleRemoveBankId = async (value) => {
    const requestInit = getRequestInit({
      method: "DELETE",
      data: value,
    });
    deleteLinkedAccount(
      `${API_URLS.LINKED_ACCOUNTS_BANKID(userId)}`,
      requestInit
    )
      .catch((error) => {
        showNotification(
          "error",
          "Något gick fel!",
          `Kunde inte ta bort bankid.`
        );
      })
      .finally(() => {
        refetchLinkedAccounts();
      });
  };

  const handleRemoveSiths = async (value) => {
    const requestInit = getRequestInit({
      method: "DELETE",
      data: value,
    });
    deleteLinkedAccount(
      `${API_URLS.LINKED_ACCOUNTS_SITHS(userId)}`,
      requestInit
    )
      .catch((error) => {
        showNotification(
          "error",
          "Något gick fel!",
          `Kunde inte ta bort bankid.`
        );
      })
      .finally(() => {
        refetchLinkedAccounts();
      });
  };

  return (
    <Card>
      <Card.Body className={"flex flex-col gap-4"}>
        <H4>Inloggningsalternativ</H4>
        <Button
          disabled={linkedAccountsFetched.hsaId != null}
          variant={"secondary"}
          width={"large"}
          onClick={() =>
            login(LOCAL_URLS.MANAGE, {
              acr: "urn:se:curity:authentication:siths:Siths",
            })
          }
        >
          Lägg till SITHS
        </Button>
        <Button
          disabled={linkedAccountsFetched.personalNumber != null}
          variant={"secondary"}
          width={"large"}
          onClick={() =>
            login(LOCAL_URLS.MANAGE, {
              acr: "urn:se:curity:authentication:bankid:BankID",
            })
          }
        >
          Lägg till BankID
        </Button>
        <H6 className={"mt-4"}>Tillagda inloggningsalternativ:</H6>
        {linkedAccountsFetched.hsaId && (
          <div className={"flex flex-row items-end gap-4"}>
            <InputField
              disabled
              type={"text"}
              width={"medium"}
              label={"SITHS"}
              defaultValue={linkedAccountsFetched.hsaId}
            />
            <Button
              type={"button"}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Ta bort SITHS"
              onClick={() => handleRemoveSiths(linkedAccountsFetched.hsaId)}
              variant={"secondary"}
              pillShape={true}
              className={"h-8"}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        )}
        {linkedAccountsFetched.personalNumber && (
          <div className={"flex flex-row gap-4 items-end"}>
            <InputField
              disabled
              type={"text"}
              width={"medium"}
              label={"BankID"}
              defaultValue={linkedAccountsFetched.personalNumber}
            />
            <Button
              type={"button"}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Ta bort BankID"
              onClick={() =>
                handleRemoveBankId(linkedAccountsFetched.personalNumber)
              }
              className={"h-8"}
              variant={"secondary"}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        )}
        {!linkedAccountsFetched.hsaId &&
          !linkedAccountsFetched.personalNumber && <i>Inga</i>}
        <Tooltip id="my-tooltip" place={"right"} />
      </Card.Body>
    </Card>
  );
};

export default LinkedAccountsView;
