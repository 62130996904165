import { P } from "@collabodoc/component-library";
import React from "react";
import styled from "styled-components";

const AlertMessages = ({ alerts }) => {
  return (
    <div className={"w-full"}>
      {alerts.map((alert, key) => {
        return (
          <div key={key} role="alert">
            <div
              className={"bg-red-500 text-white font-bold rounded-t px-4 py-2"}
            >
              {alert.heading}
            </div>
            <P
              className={
                "border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700"
              }
            >
              {alert.paragraph}
            </P>
          </div>
        );
      })}
    </div>
  );
};

export default AlertMessages;
