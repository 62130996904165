import { OidcSecure, useOidc } from "@axa-fr/react-oidc";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeContext, ThemeProvider } from "styled-components";
import { getManuals } from "./Blob";
import CareCentersView from "./carecentersmanagement/CareCentersView";
import CareCenterView from "./carecentersmanagement/CareCenterView";
import FontProvider from "./fonts/fonts";
import Footer from "./Footer";
import { getTheme } from "./themes/getTheme";
import UserManuals from "./UserManuals";
import { getTranslations, LOCALE } from "./utils/localeUtils";
import { Auth } from "./Auth";
import {
  CareCenterContext,
  CareCenterContextProvider,
} from "./CareCenterContext";
import ProductsView from "./ProductsView";
import UserListView from "./usermanagement/UserListView";
import { Dropdown, Navbar } from "@collabodoc/tailwind-components";
import "@collabodoc/tailwind-components/dist/assets/main.css";
import MyAccountView from "./myaccount/MyAccount";
import UserView from "./usermanagement/UserView";
import { LOCAL_URLS } from "./urls";
import "./output.css";
import { getTenantName } from "./utils/tenantUtils";
import AddCareCenterView from "./carecentersmanagement/AddCareCenterView";

const App = () => {
  const tenant = getTenantName();
  const [manuals, setManuals] = useState(null);

  useEffect(() => {
    getManuals().then((result) => setManuals(result));
  }, []);

  const theme = getTheme();

  return (
    <IntlProvider locale={LOCALE} messages={getTranslations()}>
      <ThemeProvider theme={theme}>
        <FontProvider />
        <Auth tenant={tenant}>
          <OidcSecure configurationName={tenant}>
            <CareCenterContextProvider>
              <BrowserRouter>
                <div className={"min-h-screen bg-gray-100"}>
                  <NavBarContainer tenant={tenant} />
                  <div className={"w-full flex flex-col items-center"}>
                    <div className={"lg:max-w-screen-lg min-w-min w-full"}>
                      <Routes>
                        <Route
                          path={"/users/user/:userId?"}
                          element={<UserView />}
                        />
                        <Route path={"/users"} element={<UserListView />} />
                        <Route
                          path={"/myaccount"}
                          element={<MyAccountView />}
                        />
                        <Route path="carecenters">
                          <Route index={true} element={<CareCentersView />} />
                          <Route path="new" element={<AddCareCenterView />} />
                          <Route path=":id" element={<CareCenterView />} />
                        </Route>
                        <Route
                          path="usermanuals"
                          element={<UserManuals manuals={manuals} />}
                        />
                        <Route
                          exact
                          path="/"
                          element={
                            <>
                              <ProductsView />
                              <Footer />
                            </>
                          }
                        />
                        <Route element={<Navigate to={"/"} />} />
                      </Routes>
                    </div>
                  </div>
                </div>
              </BrowserRouter>
            </CareCenterContextProvider>
          </OidcSecure>
        </Auth>
      </ThemeProvider>
    </IntlProvider>
  );
};

const NavBarContainer = ({ tenant }) => {
  const {
    careCenters,
    isLoadingCareCenters,
    setCurrentCareCenter,
    currentCareCenterId,
  } = useContext(CareCenterContext);
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useOidc(tenant);
  const location = useLocation();

  const handleChange = (id) => {
    setCurrentCareCenter(id);
    navigate(pathname);
  };

  const logoutHandler = async () => {
    if (isAuthenticated) {
      await logout("/");
    }
  };

  const options = useMemo(() => {
    return [
      ...(careCenters ?? []).map((cc) => ({
        id: cc.identifier,
        text: cc.name,
      })),
    ];
  }, [careCenters]);

  let navbarItems = [
    ({ className }) => (
      <a
        className={className}
        href={`${LOCAL_URLS.MANAGE}?returnUrl=${window.location.href}`}
      >
        {intl.formatMessage({ id: "navbar.myaccount" })}
      </a>
    ),
    ({ className }) => (
      <a className={className} href="#" onClick={logoutHandler}>
        {intl.formatMessage({ id: "navbar.logout" })}
      </a>
    ),
  ];

  if (!location.pathname.includes("users")) {
    navbarItems = [
      () => (
        <Dropdown
          options={options}
          selected={currentCareCenterId}
          setSelected={handleChange}
        />
      ),
      ...navbarItems,
    ];
  }
  return (
    <Navbar
      Logo={() => <img src={theme.logo} width="170px" />}
      CollapsableItems={navbarItems}
      nonCollapsableItems={[]}
    />
  );
};
export default App;
