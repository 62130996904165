import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4, P, A, HorizontalLine } from "@collabodoc/component-library";
import React from "react";
import { FormattedMessage } from "react-intl";

const SUPPORT_PHONE = "08 - 12 82 66 00";
const SUPPORT_EMAIL = "support@collabodoc.com";

const PhoneNumberLink = () => (
  <A href={`telto:${SUPPORT_PHONE.replace(/ /g, "")}`}>{SUPPORT_PHONE}</A>
);

const EmailLink = () => <A href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</A>;

const Footer = () => (
  <>
    <div className={"w-full"} tag={"footer"}>
      <HorizontalLine />
      <div>
        <H4>
          <FormattedMessage id={"support.heading"} />
        </H4>
      </div>
    </div>
    <div>
      <P>
        <FormattedMessage
          id={"support.paragraph"}
          values={{
            phone: <PhoneNumberLink />,
            email: <EmailLink />,
          }}
        />
      </P>
      <A href="/usermanuals">
        <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>{" "}
        <FormattedMessage id="usermanuals.link" />
      </A>
    </div>
  </>
);

export default Footer;
