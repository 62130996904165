import { useState } from "react";
import { Button, Table } from "@collabodoc/tailwind-components";
import ProductModal from "./ProductModal";

const ProductRow = ({
  careCenter,
  product,
  reFetchCareCenters,
  showNotification,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const activeProduct = careCenter.products.find(
    (p) => p.productId === product.id
  );

  return (
    <>
      <Table.Row>
        <Table.Data>
          {activeProduct?.productName ?? product.defaultName}
        </Table.Data>
        <Table.Data>{activeProduct?.description}</Table.Data>
        <Table.Data>{activeProduct ? "Ja" : "Nej"}</Table.Data>
        <Table.Data>
          {activeProduct?.showProductForPatients ? "Ja" : "Nej"}
        </Table.Data>
        <Table.Data>
          <Button variant="primary" onClick={() => setModalOpen(true)}>
            Ändra...
          </Button>
        </Table.Data>
      </Table.Row>
      <ProductModal
        product={activeProduct ?? product}
        open={modalOpen}
        setOpen={setModalOpen}
        careCenterId={careCenter.identifier}
        reFetchCareCenters={reFetchCareCenters}
        showNotification={showNotification}
      />
    </>
  );
};

export default ProductRow;
