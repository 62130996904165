import { OidcProvider } from "@axa-fr/react-oidc";
import React from "react";
import { LoadingView } from "@collabodoc/tailwind-components";
import PropTypes from "prop-types";

const getConfig = (tenant) => ({
  authority: `${window.REACT_APP_AUTHORITY_URL}/${tenant}/oauth-anonymous`,
  client_id: window.REACT_APP_OIDC_CLIENT_ID,
  monitor_session: true,
  redirect_uri: `${window.location.origin}/auth/signed-in`,
  silent_redirect_uri: `${window.location.origin}/auth/signed-in-silent`,
  scope: "openid profile roles carecenters carecenter_api user_api",
  service_worker_only: false,
  service_worker_relative_url: "/OidcServiceWorker.js",
});
const Auth = ({ children, tenant }) => {
  return (
    <OidcProvider
      configuration={getConfig(tenant)}
      configurationName={tenant}
      authenticatingComponent={LoadingView}
      callbackSuccessComponent={LoadingView}
      sessionLostComponent={SessionLost}
      loadingComponent={LoadingView}
    >
      {children}
    </OidcProvider>
  );
};

const SessionLost = () => {
  return (
    <div>
      <h1 className="textCenter marginTop50">Du har blivit utloggad</h1>
      <button
        onClick={() => (window.location.href = "/")}
        className="btn btn-primary "
      >
        Gå till startsidan
      </button>
    </div>
  );
};

const Callback = () => (
  <div>
    <LoadingView />
  </div>
);

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  tenant: PropTypes.string.isRequired,
};
export { Auth };
