import { H2 } from "@collabodoc/component-library";
import { Table, Card } from "@collabodoc/tailwind-components";
import ProductRow from "./ProductRow";
import { PRODUCT } from "../constants/products";

const ProductsCard = ({ careCenter, reFetchCareCenters, showNotification }) => {
  return (
    <Card className={"basis-0 grow"}>
      <Card.Header>
        <H2 className="mt-4 px-1">Produkter</H2>
      </Card.Header>
      <Card.Body className={"flex flex-col justify-between gap-4"}>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Namn</Table.Header>
              <Table.Header>Beskrivning</Table.Header>
              <Table.Header>Aktiv</Table.Header>
              <Table.Header>Visas</Table.Header>
              <Table.Header></Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {Object.values(PRODUCT).map((product) => (
              <ProductRow
                reFetchCareCenters={reFetchCareCenters}
                key={product.id}
                careCenter={careCenter}
                product={product}
                showNotification={showNotification}
              />
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ProductsCard;
