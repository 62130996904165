import {
  Button,
  Card,
  Dropdown,
  InputField,
} from "@collabodoc/tailwind-components";
import {
  ADMINISTRATORROLE as ADMIN_ROLE,
  getAdminRole,
  H2,
} from "@collabodoc/component-library";
import { ADMIN_ROLE_NAME, CLINICAL_ROLE_NAME } from "../enums";

const clinicalRoleOptions = [
  { id: null, text: "Ingen klinisk roll" },
  ...Object.entries(CLINICAL_ROLE_NAME).map(([key, value]) => ({
    id: parseInt(key),
    text: value,
  })),
];
let administrativeRoleOptions = [
  { id: null, text: "Ingen administrativ roll" },
  ...Object.entries(ADMIN_ROLE_NAME).map(([key, value]) => ({
    id: parseInt(key),
    text: value,
  })),
];

const UserInfoCard = ({
  handleSendUser,
  formRef,
  formData,
  handleFormChange,
  setFormData,
  submitEnabled,
  setSubmitEnabled,
  userId,
  oidcUser,
}) => {
  const handleClinicalRoleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      clinicalRole: e,
    }));
    setSubmitEnabled(true);
  };

  const handleAdministratorRoleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      administratorRole: e,
    }));
    setSubmitEnabled(true);
  };

  const currentUserAdminRole = getAdminRole(oidcUser?.roles);
  if (currentUserAdminRole !== ADMIN_ROLE.ADMIN) {
    administrativeRoleOptions = administrativeRoleOptions.filter(
      (x) => x.id !== ADMIN_ROLE.ADMIN
    );
  }

  return (
    <Card>
      <Card.Header>
        <H2>Användarinformation</H2>
      </Card.Header>
      <form ref={formRef} noValidate onSubmit={handleSendUser}>
        <Card.Body className={"z-0"}>
          <div className={"flex flex-col gap-4"}>
            <InputField
              required={true}
              value={formData.firstName}
              label={"Förnamn"}
              type={"text"}
              width={"large"}
              name={"firstName"}
              maxLength={50}
              onChange={(e) => handleFormChange(e)}
            />
            <InputField
              required={true}
              value={formData.lastName}
              label={"Efternamn"}
              type={"text"}
              width={"large"}
              name={"lastName"}
              maxLength={50}
              onChange={(e) => handleFormChange(e)}
            />
            <InputField
              required={true}
              value={formData.email}
              label={"E-postadress"}
              type={"email"}
              name={"email"}
              width={"large"}
              maxLength={50}
              customValidityMessages={{
                typeMismatch: "Inte en giltig epostadress",
              }}
              onChange={(e) => handleFormChange(e)}
            />
            <InputField
              required={true}
              value={formData.phoneNumber}
              label={"Mobilnummer"}
              type={"text"}
              width={"large"}
              name={"phoneNumber"}
              maxLength={20}
              pattern={
                "^(([0]{1}|[0]{2}[4][6]|[+]{1}[4][6])[7]{1}\\d{1}-?\\d{7})$"
              }
              helperText={"Fyll i enligt formatet 07X-XXXXXXXXX"}
              customValidityMessages={{
                patternMismatch: "Numret måste vara i formatet 07X-XXXXXXXXX",
              }}
              onChange={(e) => handleFormChange(e)}
            />
            <H2>Roller</H2>
            <Dropdown
              label={"Klinisk roll"}
              options={clinicalRoleOptions}
              name={"clinicalrole"}
              selected={formData.clinicalRole}
              setSelected={(e) => handleClinicalRoleChange(e)}
              width={"large"}
            ></Dropdown>
            <Dropdown
              label={"Administrativ roll"}
              options={administrativeRoleOptions}
              selected={formData.administratorRole}
              name={"administratorRole"}
              setSelected={(e) => handleAdministratorRoleChange(e)}
              width={"large"}
            ></Dropdown>
          </div>
        </Card.Body>
        <Card.Footer className={"flex-row justify-end gap-4"}>
          <Button variant="primary" type="submit" disabled={!submitEnabled}>
            {userId ? "Spara" : "Skapa användare"}
          </Button>
        </Card.Footer>
      </form>
    </Card>
  );
};

export default UserInfoCard;
