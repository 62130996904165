import {
  Button,
  Card,
  InputField,
  H2,
  H4,
} from "@collabodoc/tailwind-components";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { API_URLS } from "../urls";
import Personnummer from "personnummer";

const UserAuthMethodsCard = ({
  formData,
  setFormData,
  userId,
  showNotification,
}) => {
  const [isEditingHsaId, setIsEditingHsaId] = useState(false);
  const [isEditingBankId, setIsEditingBankId] = useState(false);
  const [bankIdInput, setBankIdInput] = useState(null);
  const [hsaIdInput, setHsaIdInput] = useState(null);
  const { doFetch } = useFetchy();

  const [showBankIdValidationError, setShowBankIdValidationError] =
    useState(null);
  const [showHsaIdValidationError, setShowHsaIdValidationError] =
    useState(null);

  const handleAddBankId = async (value) => {
    const bankIdIsValid = Personnummer.valid(value);
    if (!bankIdIsValid) {
      setShowBankIdValidationError(true);
      return;
    }
    const init = getRequestInit({
      method: "PUT",
      data: value,
    });
    const url = API_URLS.LINKED_ACCOUNTS_BANKID(userId);
    doFetch(url, init)
      .then((result) => {
        if (!result.response.ok) {
          if (result.response.status === 409) {
            showNotification(
              "warning",
              "Varning",
              "Personnumret finns redan på en annan användare"
            );
          } else {
            showNotification(
              "warning",
              "Varning",
              "Kunde inte spara personnumret."
            );
          }
        } else {
          setFormData((prevState) => ({
            ...prevState,
            bankid: value,
          }));
          setIsEditingBankId(false);
        }
      })
      .finally(() => {
        setShowBankIdValidationError(false);
      });
  };

  const handleAddSiths = async (value) => {
    const hsaIsIsValid = value?.length >= 13;
    if (!hsaIsIsValid) {
      setShowHsaIdValidationError(true);
      return;
    }
    const init = getRequestInit({
      method: "PUT",
      data: value,
    });
    const url = API_URLS.LINKED_ACCOUNTS_SITHS(userId);
    doFetch(url, init)
      .then((result) => {
        if (!result.response.ok) {
          if (result.response.status === 409) {
            showNotification(
              "warning",
              "Varning",
              "HSAID:t finns redan på en annan användare"
            );
          } else {
            showNotification("warning", "Varning", "Kunde inte spara HSAid.");
          }
        } else {
          setFormData((prevState) => ({
            ...prevState,
            hsaId: value,
          }));
          setIsEditingHsaId(false);
        }
      })
      .finally(() => {
        setShowBankIdValidationError(false);
      });
  };

  const handleRemoveLinkedAccountBankId = async (value) => {
    const init = getRequestInit({
      method: "DELETE",
      data: value,
    });
    const url = API_URLS.LINKED_ACCOUNTS_BANKID(userId);
    fetch(url, init)
      .then((response) => {
        if (!response.ok) {
          console.error("Failed deleting linked account.");
        } else {
          setFormData((prevState) => ({
            ...prevState,
            bankid: "",
          }));
        }
      })
      .finally(() => {
        setIsEditingBankId(false);
      });
  };

  const handleRemoveLinkedAccountHsaId = async (value) => {
    const init = getRequestInit({
      method: "DELETE",
      data: value,
    });
    const url = API_URLS.LINKED_ACCOUNTS_SITHS(userId);
    fetch(url, init)
      .then((response) => {
        if (!response.ok) {
          console.error("Failed deleting linked account.");
        } else {
          setFormData((prevState) => ({
            ...prevState,
            hsaId: "",
          }));
        }
      })
      .finally(() => {
        setIsEditingHsaId(false);
      });
  };

  const handleCancelEditing = () => {
    setIsEditingHsaId(false);
    setIsEditingBankId(false);
    setHsaIdInput(null);
    setBankIdInput(null);
    setShowBankIdValidationError(false);
    setShowHsaIdValidationError(false);
  };

  return (
    <Card className="w-full">
      <Card.Header>
        <H2>Inloggningsalternativ</H2>
      </Card.Header>
      <Card.Body>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-row justify-between items-start"}>
            {!formData.bankid &&
              (isEditingBankId ? (
                <>
                  <InputField
                    type={"text"}
                    label={"Personnummer"}
                    validationError={
                      showBankIdValidationError ? "Felaktigt personnummer" : ""
                    }
                    onChange={(e) => setBankIdInput(e.target.value)}
                  />
                  <Button
                    className={"ml-2 mt-6"}
                    variant={"primary"}
                    onClick={() => handleAddBankId(bankIdInput)}
                  >
                    Spara
                  </Button>
                  <Button
                    className={"ml-2 mt-6"}
                    variant={"secondary"}
                    onClick={handleCancelEditing}
                  >
                    Avbryt
                  </Button>
                </>
              ) : (
                <Button
                  disabled={isEditingHsaId}
                  variant={"secondary"}
                  width={"full"}
                  onClick={() => setIsEditingBankId(true)}
                >
                  Lägg till Bankid
                </Button>
              ))}
          </div>
          <div className={"flex flex-row justify-between items-start"}>
            {!formData.hsaId &&
              (isEditingHsaId ? (
                <>
                  <InputField
                    type={"text"}
                    maxLength={31}
                    label={"HsaId"}
                    validationError={
                      showHsaIdValidationError ? "Felaktigt HSAId" : ""
                    }
                    onChange={(e) => setHsaIdInput(e.target.value)}
                  />
                  <Button
                    className={"ml-2 mt-6"}
                    variant={"primary"}
                    onClick={() => handleAddSiths(hsaIdInput)}
                  >
                    Spara
                  </Button>
                  <Button
                    className={"ml-2 mt-6"}
                    variant={"secondary"}
                    onClick={handleCancelEditing}
                  >
                    Avbryt
                  </Button>
                </>
              ) : (
                <Button
                  disabled={isEditingBankId}
                  variant={"secondary"}
                  width={"full"}
                  onClick={() => setIsEditingHsaId(true)}
                >
                  Lägg till HsaId
                </Button>
              ))}
          </div>
          <div>
            <H4>Tillagda inloggningsalternativ</H4>
            {formData.bankid && (
              <div className={"flex items-end gap-2"}>
                <InputField
                  type={"text"}
                  label={"BankId"}
                  disabled={true}
                  value={formData.bankid}
                />
                <Button
                  className={"h-9"}
                  variant={"secondary"}
                  type={"button"}
                  onClick={() =>
                    handleRemoveLinkedAccountBankId(formData.bankid)
                  }
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            )}
            {formData.hsaId && (
              <div className={"flex items-end gap-2"}>
                <InputField
                  type={"text"}
                  label={"HsaId"}
                  disabled={true}
                  value={formData.hsaId}
                />
                <Button
                  className={"h-9"}
                  variant={"secondary"}
                  type={"button"}
                  onClick={() => handleRemoveLinkedAccountHsaId(formData.hsaId)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            )}
            {!formData.bankId && !formData.hsaId && (
              <p>Inga tillagda inloggningsalternativ</p>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserAuthMethodsCard;
