import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import App from "./App";
import "./output.css";
import * as serviceWorker from "./serviceWorker";
import { CacheBuster } from "@collabodoc/component-library";
import packageJson from "../package.json";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CacheBuster currentVersion={packageJson.version}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return <App />;
      }}
    </CacheBuster>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
