export const multiFilter = (questions = [], searchText = "", keys = []) => {
  const searchWords = searchText.split(" ");
  return questions.filter((question) =>
    searchWords.every((searchWord) =>
      keys.some(
        (key) =>
          question[key] &&
          String(question[key]).toLowerCase().includes(searchWord)
      )
    )
  );
};
