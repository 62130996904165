import { H1 } from "@collabodoc/component-library";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CareCenterContext } from "../CareCenterContext";
import ProductsCard from "./ProductsCard";
import CareCenterInformationCard from "./CareCenterInformationCard";
import {
  Button,
  Card,
  Notification,
  useShowNotification,
} from "@collabodoc/tailwind-components";
import { LOCAL_URLS } from "../urls";

const CareCenterView = () => {
  const { id } = useParams();
  const { getCareCenterByIdentifier, reFetchCareCenters, careCenters } =
    useContext(CareCenterContext);
  const careCenter = getCareCenterByIdentifier(id);
  const navigate = useNavigate();
  const { notification, showNotification } = useShowNotification();

  return (
    <>
      <Notification notification={notification} />
      <div className={"flex flex-col mt-4 px-8 gap-4"}>
        <div className={"basis-full"}>
          <H1>Hantera enhet</H1>
        </div>
        <div className={"flex gap-y-4 gap-x-4 flex-wrap"}>
          <CareCenterInformationCard
            showNotification={showNotification}
            careCenter={careCenter}
            careCenters={careCenters}
          />
          <ProductsCard
            careCenter={careCenter}
            reFetchCareCenters={reFetchCareCenters}
            showNotification={showNotification}
          />
        </div>
        <div className={"basis-full"}>
          <Card className={"w-full"}>
            <Card.Body className={"justify-between"}>
              <Button
                variant="secondary"
                type={"button"}
                onClick={() => navigate(LOCAL_URLS.CARECENTERS)}
              >
                Tillbaka
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CareCenterView;
