import { A, H2, PrimaryButton } from "@collabodoc/component-library";
import { faChevronLeft, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";

const UserManuals = ({ manuals }) => {
  const handleClick = () => {
    window.location.href = "/";
  };

  return (
    <>
      <H2>
        <FormattedMessage id={"usermanuals"} />
      </H2>
      {manuals && manuals.length > 0 && (
        <>
          {manuals.map((manual, key) => (
            <>
              <A key={key} href={manual.url} target="_blank">
                <FontAwesomeIcon icon={faFile}></FontAwesomeIcon> {manual.name}
              </A>
              <br />
            </>
          ))}
        </>
      )}
      <br />
      <div>
        <PrimaryButton onClick={handleClick}>
          <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
          <FormattedMessage id={"back"} />
        </PrimaryButton>
      </div>
    </>
  );
};

export default UserManuals;
